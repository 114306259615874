import React from 'react'
import school from '../../assets/School bus.gif'
import lab from '../../assets/com.gif'
import arts from '../../assets/finearts.gif'
import sports from '../../assets/sports.gif'
import lib from '../../assets/library.gif'
import science from '../../assets/sciencelab.gif'


const Highlights = () => {
	return (
		<>
			<section className="m-4 md:m-8" style={{ marginTop: '50px', marginBottom: '50px' }}>
				<div className="container mx-auto p-4 my-6 space-y-2 text-center">
					<h2 className="text-5xl font-bold text-blue-800 custom-font-stack">Explore Our Facilities</h2>
					<p className="dark:text-red-500 font-semibold custom-font-stack">Begin Your Journey</p>
				</div>
				<div className="container mx-auto grid justify-center gap-4 sm:grid-cols-2 lg:grid-cols-3">

					<div className="flex flex-col items-center p-4">
						<img src={school} alt="School Bus" className="w-22 h-22 " />

						<h3 className="my-3 text-3xl font-semibold custom-font-stack">Transportation</h3>
						<div className="space-y-1 leading-tight custom-font-stack">
							<p style={{ color: 'black' }}>Convenient and Reliable</p>
							<p style={{ color: 'black' }}>Safety First</p>
							<p style={{ color: 'black' }}>Efficient Routes</p>
						</div>
					</div>
					<div className="flex flex-col items-center p-4">

						<img src={lab} alt="School Bus" className="w-22 h-17" />

						<h3 className="my-3 text-3xl font-semibold custom-font-stack">Computer Lab</h3>
						<div className="space-y-1 leading-tight custom-font-stack">
							<p style={{ color: 'black' }}>State-of-the-Art Facilities</p>
							<p style={{ color: 'black' }}>Expert Guidance</p>
							<p style={{ color: 'black' }}>Safe and Secure</p>
						</div>
					</div>
					<div className="flex flex-col items-center p-4">

						<img src={science} alt="School Bus" className="w-22 h-18" />

						<h3 className="my-3 text-3xl font-semibold custom-font-stack">Science Lab</h3>
						<div className="space-y-1 leading-tight custom-font-stack">
							<p style={{ color: 'black' }}>Interactive Learning</p>
							<p style={{ color: 'black' }}>Real-World Applications</p>
							<p style={{ color: 'black' }}>Expert Instruction</p>
						</div>
					</div>
					<div className="flex flex-col items-center p-4">

						<img src={lib} alt="School Bus" className="w-23 h-23" />

						<h3 className="my-3 text-3xl font-semibold custom-font-stack">Library</h3>
						<div className="space-y-1 leading-tight custom-font-stack">
							<p style={{ color: 'black' }}>Comfortable and Inviting Space</p>
							<p style={{ color: 'black' }}>Digital Resources</p>
							<p style={{ color: 'black' }}>Supportive Staff</p>
						</div>
					</div>
					<div className="flex flex-col items-center p-4">

						<img src={sports} alt="School Bus" className="w-23 h-23" />

						<h3 className="my-3 text-3xl font-semibold custom-font-stack">Sports Facilities</h3>
						<div className="space-y-1 leading-tight custom-font-stack">
							<p style={{ color: 'black' }}>Indoor and Outdoor Facilities</p>
							<p style={{ color: 'black' }}>Diverse Programs</p>
							<p style={{ color: 'black' }}>Health and Safety</p>
						</div>
					</div>
					<div className="flex flex-col items-center p-4">

						<img src={arts} alt="School Bus" className="w-22 h-17" />

						<h3 className="my-3 text-3xl font-semibold custom-font-stack">Fine Arts</h3>
						<div className="space-y-1 leading-tight custom-font-stack">
							<p style={{ color: 'black' }}>Visual Arts</p>
							<p style={{ color: 'black' }}>Expert Instruction</p>
							<p style={{ color: 'black' }}>Inclusive and Supportive Environment</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Highlights
