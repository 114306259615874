import React from 'react'
// import './management.css'
// import dipika from '../../assets/dipika.png'
// import balaji from '../../assets/b1.jpg'
// import kavitha from '../../assets/kavitha.jpg'
import secretory from '../../assets/sec1.jpg'
import chairman from '../../assets/chair.JPG'

const Management = () => {
  return (
    <>

      <div class="w-full">
        <section class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
          <div class="text-center pb-12">
            {/* <h2 class="text-base font-bold text-red-500">
              Welcome to our dedicated team of leaders who are committed to providing excellence in education. Each principal brings a wealth of experience and passion to their respective branches, ensuring that every student receives the best possible education.
            </h2>
            <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-white">
              Meet our Principals
            </h1> */}


            <p className="font-serif text-4xl" style={{ margin: '0' }}>
              <span className="text-black custom-font-stack">Connect with Our Board of Trustees:</span>
              <br />
              <span className="text-red-600"> Mr.Jayashankar As Chairman and Mrs. Rekha as Secretary!</span>
            </p>

          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div class="w-full bg-gray-900 rounded-lg sahdow-lg overflow-hidden flex flex-col md:flex-row">
              <div class="w-full md:w-2/5 h-80">
                <img class="object-center object-cover w-full h-full" src={chairman} alt="kudlu gate principal" />
              </div>
              <div class="w-full md:w-3/5 text-left p-4 md:p-4 space-y-2">
                <p class="text-xl text-white font-bold custom-font-stack">Mr. Jayashankar</p>
                {/* <p class="text-base text-white font-normal">Branch: Kundlu Gate</p> */}
                <p class="text-base leading-relaxed text-white font-normal custom-font-stack">As Chairman of New Macaulay English School, I am dedicated to fostering academic excellence and character development. Our mission is to create an inclusive, innovative environment where students thrive. We prioritize safety and support, ensuring students grow academically, socially, and emotionally. Through strategic planning and community engagement, we empower students to become confident and compassionate global citizens.</p>
              </div>
            </div>
            <div class="w-full bg-gray-900 rounded-lg sahdow-lg overflow-hidden flex flex-col md:flex-row">
              <div class="w-full md:w-2/5 h-80">
                <img class="object-center object-cover w-full h-full" src={secretory} alt="sarjapura principal" />
              </div>
              <div class="w-full md:w-3/5 text-left p-6 md:p-4 space-y-2">
                <p class="text-xl text-white font-bold custom-font-stack">Mrs. Rekha</p>
                {/* <p class="text-base text-white font-normal">Branch: Sarjapura, Sompura Gate</p> */}
                <p class="text-base leading-relaxed text-white font-normal custom-font-stack">
                  As the Secretary of New Macaulay English School, I am proud to share our curriculum emphasizes academic excellence and essential life skills. We prioritize hands-on experiences, empowering students in critical thinking, problem-solving, and communication. Our mission is to prepare each student for a bright future with confidence and competence. Our dedicated faculty foster an inclusive environment, encouraging every student to explore their passions and talents.
                </p>

              </div>
            </div>
            {/* <div class="w-full bg-gray-900 rounded-lg sahdow-lg overflow-hidden flex flex-col md:flex-row">
              <div class="w-full md:w-2/5 h-80">
                <img class="object-center object-cover w-full h-full" src={kavitha} alt="bagur principal" />
              </div>
              <div class="w-full md:w-3/5 text-left p-4 md:p-4 space-y-2">
                <p class="text-xl text-white font-bold">Kavitha Ramaiah</p>
                <p class="text-base text-white font-normal">Branch: Bagur</p>
                <p class="text-base leading-relaxed text-white font-normal">
                As your principal, I am delighted to continue our journey of education and growth together. Our school has always been a beacon of excellence, innovation, and community, and I am honored to lead such a dedicated and vibrant team.    Our goal is to spark curiosity, foster critical thinking, and promote overall wellbeing.               </p>
              
              </div>
            </div> */}


          </div>
        </section>
      </div>
    </>
  )
}

export default Management
