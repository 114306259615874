import React, { useState } from 'react';
import logo from '../../assets/MHS.png';
import './nav.css';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='n'>
      <nav className="flex flex-wrap items-center justify-between p-3 bg-[#e8e8e5]">
        <div className="text-xl">
          <img src={logo} alt="Bappa Flour Mill" width="150px" />
        </div>
        <div className="flex md:hidden">
          <button id="hamburger" onClick={toggleMenu} aria-label="Toggle menu">
            {isOpen ? (
              <img className="toggle block" src="https://img.icons8.com/fluent-systems-regular/2x/close-window.png" width="40" height="40" alt="Close menu" />
            ) : (
              <img className="toggle block" src="https://img.icons8.com/fluent-systems-regular/2x/menu-squared-2.png" width="40" height="40" alt="Open menu" />
            )}
          </button>
        </div>
        <div className={`md:flex ${isOpen ? 'block' : 'hidden'} w-full md:w-auto md:text-right md:font-bold mt-5 md:mt-0 custom-font-stack`}>
          <a href="/" className="block md:inline-block hover:text-red-500 px-3 py-3">Home</a>
          {/* <a href="#" className="block md:inline-block hover:text-red-500 px-3 py-3">About Us</a> */}
          <a href="https://www.newmacaulayenglishschool.com/" target="_blank" rel="noopener noreferrer" className="block md:inline-block hover:text-red-500 px-3 py-3">Unit 1</a>
          <a href="http://unit2.newmacaulayenglishschool.com/" target="_blank" rel="noopener noreferrer" className="block md:inline-block hover:text-red-500 px-3 py-3">Unit 2</a>
          <a href="http://unit3.newmacaulayenglishschool.com/" target="_blank" rel="noopener noreferrer" className="block md:inline-block hover:text-red-500 px-3 py-3">Unit 3</a>
          {/* <a href="#" className="block md:inline-block hover:text-red-500 px-3 py-3">Management</a> */}
        </div>
        <div className="w-full md:w-auto text-end px-2 py-2 md:rounded custom-font-stack">
          <a href="tel:+91 8049524676">
            <div className="flex justify-end">
              <div className="flex items-center h-10 w-full md:w-30 rounded-md bg-red-500 text-white font-medium p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
                Contact Us
              </div>
            </div>
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
