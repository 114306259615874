import React from 'react';

const Hero = () => {
  return (
    <div className="min-h-screen  w-full bg-gradient-to-br bg-black dark:bg-gray-900">
      <div className="mx-auto max-w-7xl pt-16 sm:pt-24">
        <div className="space-y-8 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
            <div className="space-y-8">
              <div className="space-y-4">
                <div className="space-y-2">
                  <span className="rounded-full uppercase bg-pink-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white custom-font-stack " >
                    Knowledge Is Supreme
                  </span>
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl .custom-font-stack"  >
                    New Macaualay English
                    <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-pink-600 custom-font-stack">  Group</span>
                    <br />
                    Of School
                  </h1>
                </div>
                <p className="text-base text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl custom-font-stack"  >
                  At New Macaulay English School, we foster a dynamic learning environment where students are encouraged to explore their passions, develop critical thinking skills, and embrace lifelong learning.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center w-full lg:col-span-6">
            <div className="px-6 h-96 lg:h-full w-full max-w-2xl mx-auto">
              <iframe
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/5OdPYZfq5UU?si=n5BbE9LAzg7kEeo7"
                title="YouTube video player"
                id="widget2"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
