import React from 'react';
import right from '../../assets/r3.svg';
import vision from '../../assets/vision.PNG';

const Home = () => {
    return (
        <>
            <div className="bg-gradient-to-br bg-black dark:bg-gray-900">
                <div className="text-white container mx-auto p-8 overflow-hidden md:rounded-lg md:p-10 lg:p-12">
                    <div className="grid gap-8 md:grid-cols-2">
                        <div className="flex flex-col justify-center">
                            <h2 className="text-4xl font-bold text-white custom-font-stack">What We Offer</h2>
                            <div className="h-6"></div>
                            <p className="font-serif text-xl text-white md:pr-10">
                                At New Macaulay English School, we are committed to providing an enriching educational experience that fosters academic excellence, personal growth, and social responsibility. Our dedicated team of highly qualified educators utilizes innovative teaching methods to create a dynamic and engaging learning environment for all students, from nursery to 10th standard.                            </p>
                            <div className="grid grid-cols-2 gap-4 pt-8 border-t border-gray-800">
                                <div>
                                    <p className="font-semibold text-white custom-font-stack">Comprehensive Education</p>
                                    <div className="h-4"></div>
                                    <p className="font-serif text-white custom-font-stack">
                                        Our school offers a holistic and rigorous curriculum that promotes academic excellence and critical thinking skills.
                                    </p>
                                </div>
                                <div>
                                    <p className="font-semibold text-white custom-font-stack">Enriching Extracurriculars</p>
                                    <div className="h-4"></div>
                                    <p className="font-serif text-white">
                                        We provide a wide range of extracurricular activities, including sports, clubs, arts programs, and community service opportunities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <div className="w-full h-96 rounded-lg overflow-hidden relative bg-gradient-to-br from-gray-900 to-black">
                                <img src={right} alt="right img" className="w-full h-full object-cover" />
                            </div>
                        </div>
                    </div>

                    {/* <div className="h-32 md:h-40"></div>

                    <p className="font-serif text-4xl">
                        <span className="text-white custom-font-stack">United for Excellence: New Macaulay English School</span>
                        <span className="text-red-600"> Campuses in Harmony!</span>
                    </p>

                    <div className="h-32 md:h-40"></div>

                    <div className="grid gap-4 md:grid-cols-3">
                        <div className="flex-col p-8 py-16 rounded-lg shadow-2xl md:p-12 bg-gradient-to-br from-gray-900 to-black">
                            <p className="flex items-center justify-center text-4xl font-semibold text-green-400 bg-green-800 rounded-full shadow-lg w-14 h-14">
                                1
                            </p>
                            <div className="h-6"></div>
                            <p className="font-serif text-3xl">Branch: Kudlu Gate, Hosapalya Road, Bangalore- 560068</p>
                            <div className="h-4"></div>
                            <a href="URL_to_Branch_Details_Page" className="text-green-400 underline">More Details</a>
                        </div>
                        <div className="flex-col p-8 py-16 rounded-lg shadow-2xl md:p-12 bg-gradient-to-b from-gray-900 to-black">
                            <p className="flex items-center justify-center text-4xl font-semibold text-indigo-400 bg-indigo-800 rounded-full shadow-lg w-14 h-14">
                                2
                            </p>
                            <div className="h-6"></div>
                            <p className="font-serif text-3xl">
                                Branch: Sarjapura Road, Sompura gate, Bangalore-562125
                            </p>
                            <div className="h-4"></div>
                            <a href="URL_to_Branch_Details_Page" className="text-indigo-400 underline">More Details</a>
                        </div>
                        <div className="flex-col p-8 py-16 rounded-lg shadow-2xl md:p-12 bg-gradient-to-bl from-gray-900 to-black">
                            <p className="flex items-center justify-center text-4xl font-semibold text-teal-400 bg-teal-800 rounded-full shadow-lg w-14 h-14">
                                3
                            </p>
                            <div className="h-6"></div>
                            <p className="font-serif text-3xl">Branch: Bagur, Chikkathirupathi Road, Anugonadanahalli (H), Hosakote Tq.
</p>
                            <div className="h-4"></div>
                            <a href="URL_to_Branch_Details_Page" className="text-teal-400 underline">More Details</a>
                        </div>
                    </div>

                    <div className="h-40"></div> */}
                    <div style={{backgroundColor: '#e8e8e5', marginTop: '50px', marginRight: '-50px', marginLeft: '-50px'}}>
                         <div className="h-32 md:h-40" style={{height: '3rem'}}></div>

                    <p className="font-serif text-4xl" style={{margin:'0'}}>
                        <span className="text-black custom-font-stack">United for Excellence: New Macaulay English School</span>
                        <br />
                        <span className="text-red-600"> Campuses in Harmony!</span>
                    </p>

                    <div className="h-32 md:h-40"  style={{height: '2rem'}}></div>

                    <div className="grid gap-4 md:grid-cols-3">
                        <div className="flex-col p-8 py-16 rounded-lg shadow-2xl md:p-12 bg-gradient-to-br from-gray-900 to-black">
                            <p className="flex items-center justify-center text-4xl font-semibold text-green-400 bg-green-800 rounded-full shadow-lg w-14 h-14">
                                1
                            </p>
                            <div className="h-6"></div>
                            <p className="font-serif text-3xl">Branch: Kudlu Gate, Hosapalya Road, Bangalore- 560068</p>
                            <div className="h-4"></div>
                            <a href="https://maps.app.goo.gl/fy5sWRZMabWBpLxJ9"  target='blank' rel="noopener noreferrer" className="text-green-400 underline">More Details</a>
                        </div>
                        <div className="flex-col p-8 py-16 rounded-lg shadow-2xl md:p-12 bg-gradient-to-b from-gray-900 to-black">
                            <p className="flex items-center justify-center text-4xl font-semibold text-indigo-400 bg-indigo-800 rounded-full shadow-lg w-14 h-14">
                                2
                            </p>
                            <div className="h-6"></div>
                            <p className="font-serif text-3xl">
                                Branch: Sarjapura Road, Sompura gate, Bangalore-562125
                            </p>
                            <div className="h-4"></div>
                            <a href="https://maps.app.goo.gl/BNqy9YAReMot4d369"  target= 'blank' rel="noopener noreferrer" className="text-indigo-400 underline">More Details</a>
                        </div>
                        <div className="flex-col p-8 py-16 rounded-lg shadow-2xl md:p-12 bg-gradient-to-bl from-gray-900 to-black">
                            <p className="flex items-center justify-center text-4xl font-semibold text-teal-400 bg-teal-800 rounded-full shadow-lg w-14 h-14">
                                3
                            </p>
                            <div className="h-6"></div>
                            <p className="font-serif text-3xl">Branch: Bagur, Chikkathirupathi Road, Anugonadanahalli (H), Hosakote Tq.
</p>
                            <div className="h-4"></div>
                            <a href="https://maps.app.goo.gl/1p3wsiiqhfa3HpDKA" target='blank' rel="noopener noreferrer" className="text-teal-400 underline">More Details</a>
                        </div>
                    </div>

                    <div className="h-40" style={{height: '3rem'}}></div>
                    </div>

                    <div className="grid gap-8 md:grid-cols-3">
                        <div className="flex flex-col justify-center md:col-span-2">
                            <h2 className="text-4xl font-bold custom-font-stack">Our Vision, Mission, and Ranking</h2>
                            <div className="h-6"></div>
                            <p className="font-serif text-xl text-white md:pr-10">
                                "Our Vision, Mission, and Ranking showcase our commitment to excellence, empowerment, and academic achievements, guiding us towards a future of success and growth."
                            </p>
                            <div className="h-8"></div>
                            <div className="grid gap-6 pt-8 border-t border-gray-800 lg:grid-cols-3">
                                <div>
                                    <p className="font-semibold text-yellow-500 custom-font-stack">Our Vision</p>
                                    <div className="h-4"></div>
                                    <p className="font-serif text-white">
                                        "Empowering Minds with Excellence."
                                    </p>
                                </div>
                                <div>
                                    <p className="font-semibold text-yellow-500 custom-font-stack">Our Mission</p>
                                    <div className="h-4"></div>
                                    <p className="font-serif text-white">
                                        "Nurturing Growth Through Passionate Education."
                                    </p>
                                </div>
                                <div>
                                    <p className="font-semibold text-yellow-500 custom-font-stack">Our Ranking</p>
                                    <div className="h-4"></div>
                                    <p className="font-serif text-white">
                                        "Achieving Excellence in Education and Beyond."
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <div className="w-full h-96 rounded-lg overflow-hidden relative bg-gradient-to-br from-gray-900 to-black">
                                <img src={vision} alt="vision img" className="w-full h-full object-cover" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
