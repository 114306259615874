import React from 'react'
import './introduction.css'
import a from '../../assets/a3.png'
import u2 from '../../assets/u22.png'
import u3 from '../../assets/School3.jpg'

const Introduction = () => {

  return (
    <>
      <div className='in ml-10 mr-10' id='introduction'>
        <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-white dark:bg-dark">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center justify-between -mx-4" style={{ marginTop: '-50px' }}>
              <div className="w-full px-4 lg:w-6/12">
                <div className="flex items-center -mx-3 sm:-mx-4">
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="py-3 sm:py-4">

                      <div class="card">
                        <div class="bg">
                          <img src={a} alt="Placeholder" class="card-img" />
                        </div>
                        <div class="blob"></div>
                      </div>
                    </div>
                    <div className="py-3 sm:py-4">

                      <div class="card">
                        <div class="bg">
                          <img src={u2} alt="Placeholder" class="card-img" />
                        </div>
                        <div class="blob"></div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="relative z-10 my-4">
                      <img
                        src={u3}
                        alt="u3 img"
                        className="w-full rounded-2xl"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                <div className="mt-10 lg:mt-0">
                  <span className="block mb-4 text-lg font-semibold text-primary text-blue-800  custom-font-stack" style={{ fontSize: '2.12rem' }}>
                    Why Choose Us?
                  </span>
                  <h2 className="mb-5 text-3xl  font-bold text-dark text-red-500 lg:mb-8 md:text-lg lg:text-xl dark:text-red-500sm:text-[40px]/[48px]  custom-font-stack">
                    "Creating Joyful Learning Experiences Through Exceptional Services"</h2>
                  <p className="mb-5 text-base text-black text-body-color dark:text-dark-6  custom-font-stack"  >
                    Choosing the right school is a critical decision, and we are confident that our institution offers an exceptional environment for your child's education. Our dedication to academic success is unwavering, with a rigorous curriculum designed to challenge students and foster a love for learning. Our experienced educators are passionate about teaching and committed to helping each student achieve their highest potential.
                  </p>
                  <p className="mb-8 text-base text-black text-body-color dark:text-dark-6  custom-font-stack" >
                    In conclusion, choosing us means choosing a school that prioritizes academic excellence, supports personal growth, offers diverse opportunities, provides top-notch facilities, and fosters a welcoming and supportive community. We believe that these qualities make us an excellent choice for students seeking a fulfilling and enriching educational experience.
                  </p>
                </div>
              </div>


            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Introduction
