import React from 'react'
import './pri.css'
import dipika from '../../assets/dipika.png'
import balaji from '../../assets/b1.jpg'
import kavitha from '../../assets/kavitha.jpg'

const Pri = () => {
    return (
        <>
            <div className='mt-10 mb-10 p-10 bg-gradient-to-br bg-black dark:bg-gray-900'>
                <div class="main1">
                    <div class="text-center pb-12">
                        <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-white custom-font-stack">
                            Meet our Principals
                        </h1>
                        <h2 class="text-base font-bold text-yellow-600 custom-font-stack">
                            Welcome to our dedicated team of leaders who are committed to providing excellence in education. Each principal brings a wealth of experience and passion to their respective branches, ensuring that every student receives the best possible education.
                        </h2>

                    </div>
                    <ul class="cards5  mt-5 mb-5">

                        <li class="cards5_item5">
                            <div class="card5" tabindex="0">
                                <div class="card5_image5"><img src={dipika} /></div>
                                <div class="card5_content5">
                                    <h2 class="card5_title5 custom-font-stack">Branch: Kudlu Gate</h2>
                                    <div class="card5_text5">
                                        <span class="note custom-font-stack">Dipika Pandey</span>
                                        <p className='custom-font-stack'>As your principal, I am thrilled to embark on another year of learning and growth with you. Together, we have built a community that values excellence, creativity, and dedication. Let's continue to inspire and support one another in our journey towards success.</p>
                                        <p className='custom-font-stack'>Our collective efforts have made <strong> our school a nurturing environment</strong> where students thrive.</p>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="cards5_item5">
                            <div class="card5" tabindex="0">
                                <div class="card5_image5"><img src={balaji} /></div>
                                <div class="card5_content5">
                                    <h2 class="card5_title5 custom-font-stack">Branch: Sompura Gate</h2>
                                    <div class="card5_text5 custom-font-stack">
                                        <span class="note custom-font-stack">Balaji R</span>
                                        <p className='custom-font-stack'>As your principal, I am delighted to begin another year of learning and growth with you. Let's embrace this new school year with enthusiasm and a shared vision for success.</p>
                                        <p className='custom-font-stack'>Our school community<strong> continues to shine with its commitment to excellence, creativity, and dedication.</strong> The collaborative spirit of our students and staff has created a supportive environment. </p>
                                    </div>
                                </div>
                            </div>
                        </li>



                        <li class="cards5_item5">
                            <div class="card5" tabindex="0">
                                <div class="card5_image5"><img src={kavitha} /></div>
                                <div class="card5_content5">
                                    <h2 class="card5_title5 custom-font-stack">Branch: Bagur</h2>
                                    <div class="card5_text5">
                                        <span class="note custom-font-stack">Kavitha Ramaiah</span>
                                        <p className='custom-font-stack'>As your principal, I am delighted to continue our journey of education and growth together. Our school has</p>
                                        <p className='custom-font-stack'>always been a  <strong>beacon of excellence, innovation, and community,</strong> and I am honored to lead such a dedicated and vibrant team. Our goal is to spark curiosity, foster critical thinking, and promote overall wellbeing.</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


        </>
    )
}

export default Pri
