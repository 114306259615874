// import React from 'react';
// import logo from '../../assets/MHS0.png';
// import './f.css'

// const F = () => {
//     return (
//         <>
//             <footer className="bg-gradient-to-br bg-black dark:bg-gray-900">
//                 <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
//                     <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
//                         <div className="custom-font-stack">
//                             <img
//                                 src={logo}
//                                 className="mr-5 h-6 sm:h-9"
//                                 alt="logo"
//                                 style={{ height: '5.25rem', marginLeft: '70px' }}
//                             />
//                             <p className="max-w-xs mt-4 ml-5 text-sm text-white">
//                                 New Macaulay <span style={{ color: 'yellow' }}>English School</span>
//                             </p>
//                             <div className="flex mt-8 space-x-6 text-black" style={{ marginLeft: '100px' }}>
//                                 <a
//                                     className=""
//                                     href='https://instagram.com/nmehs7?utm_source=qr&igsh=d2x4NXZhajQzaHFk'
//                                     target="_blank"
//                                     rel="noreferrer"
//                                 >
//                                     <span className="sr-only"> Instagram </span>
//                                     <svg className="w-7 h-7" fill="red" viewBox="0 0 24 24" aria-hidden="true">
//                                         <path
//                                             fillRule="evenodd"
//                                             d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
//                                             clipRule="evenodd"
//                                         />
//                                     </svg>
//                                 </a>

//                                 <a
//                                     className=""
//                                     href="https://facebook.com/share/bRDUkRszHg7sRRcf/?mibextid=oFDknk"
//                                     target="_blank"
//                                     rel="noreferrer"
//                                 >
//                                     <span className="sr-only">Facebook</span>
//                                     <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         width="24"
//                                         height="24"
//                                         viewBox="0 0 24 24"
//                                         aria-hidden="true"
//                                     >
//                                         <path
//                                             fill="#1877F2"
//                                             d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.894-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.794.714-1.794 1.76v2.307h3.587l-.467 3.622h-3.12V24h6.116C23.407 24 24 23.407 24 22.675V1.325C24 .593 23.407 0 22.675 0z"
//                                         />
//                                     </svg>
//                                 </a>

//                                 <a
//                                     className=""
//                                     href='https://youtube.com/@NewMacaulayHighSchool?si=M8TEhnGL7EE6CRx'
//                                     target="_blank"
//                                     rel="noreferrer"
//                                 >
//                                     <span className="sr-only"> YouTube </span>
//                                     <svg className="w-7 h-7" fill="red" viewBox="0 0 24 24" aria-hidden="true">
//                                         <path
//                                             d="M23.498 6.186a2.987 2.987 0 0 0-2.112-2.112C19.606 3.5 12 3.5 12 3.5s-7.606 0-9.386.574A2.987 2.987 0 0 0 .502 6.186C.007 8.093 0 12 0 12s.007 3.907.502 5.814a2.987 2.987 0 0 0 2.112 2.112C4.394 20.5 12 20.5 12 20.5s7.606 0 9.386-.574a2.987 2.987 0 0 0 2.112-2.112C23.993 15.907 24 12 24 12s-.007-3.907-.502-5.814zM9.75 15.02V8.98l6.25 3.02-6.25 3.02z"
//                                         />
//                                     </svg>
//                                 </a>
//                             </div>
//                         </div>

//                         <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
//                             <div className="custom-font-stack">
//                                 <p className="font-medium text-white">Branch 1</p>
//                                 <nav className="flex flex-col mt-4 space-y-2 text-sm text-white">
//                                     <a className="hover:text-red-500" href="mailto:macaulayschool2010@gmail.com">macaulayschool2010@gmail.com</a>
//                                     <a className="hover:text-red-500" href="tel:+91 9739156824">+91-9739156824</a>
//                                 </nav>
//                             </div>

//                             <div className="custom-font-stack ml-5">
//                                 <p className="font-medium text-white">Branch 2</p>
//                                 <nav className="flex flex-col mt-4 space-y-2 text-sm text-white">
//                                     <a className="hover:text-red-500" href="mailto:newmacaulayschool2012@gmail.com">newmacaulayschool2012@gmail.com</a>
//                                     <a className="hover:text-red-500" href="tel:+91 9148788100">+91-9148788100 </a>
//                                 </nav>
//                             </div>

//                             <div className="custom-font-stack ml-14">
//                                 <p className="font-medium text-white">Branch 3</p>
//                                 <nav className="flex flex-col mt-4 space-y-2 text-sm text-white">
//                                     <a className="hover:text-red-500" href="mailto:macaulayschoolbaguru@gmail.com">macaulayschoolbaguru@gmail.com</a>
//                                     <a className="hover:text-red-500" href="tel:+91 7624856170">+91-7624856170
//                                     </a>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <p
//                     className="mt-8  text-large text-black custom-font-stack"
//                     style={{ padding: '10px', backgroundColor: '#e8e8e5' }}
//                 >
//                     © 2022 Macaulay Group of Schools | Designed & Developed by Drighna Technology pvt Ltd.
//                 </p>
//             </footer>
//         </>
//     );
// }

// export default F;
import React from 'react';
import logo from '../../assets/MHS0.png';

const F = () => {
  return (
    <>
      <footer className="bg-gradient-to-br from-black via-gray-900 to-black dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div className="grid ml-10 grid-cols-1 gap-8 lg:grid-cols-3">
            <div className="custom-font-stack text-center lg:text-left">
              <img 
                src={logo} 
                className="mx-auto lg:mx-0 h-24" 
                alt="logo" 
              />
              <p className="max-w-xs mt-4 text-sm text-white mx-auto lg:ml-5">
                New Macaulay <span style={{ color: 'yellow' }}>English School</span>
              </p>
             
            </div>

            <div className="grid mt-5 mr-10 grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-3">
              <div className="custom-font-stack text-center sm:text-center">
                <a className="font-medium text-white hover:text-red-500" href='https://www.newmacaulayenglishschool.com' target='blank' rel="noopener noreferrer">Unit 1</a>
                <nav className="flex flex-col mt-4 space-y-2 text-sm text-white">
                  <a className="hover:text-red-500" href="/">macaulayschool2010@gmail.com</a>
                  <a className="hover:text-red-500" href="/">+91-9739156824</a>
                </nav>
              </div>

              <div className="custom-font-stack text-center sm:text-center">
                <a className="font-medium text-white hover:text-red-500" href='http://unit2.newmacaulayenglishschool.com' target='blank' rel="noopener noreferrer">Unit 2</a>
                <nav className="flex flex-col mt-4 space-y-2 text-sm text-white">
                  <a className="hover:text-red-500" href="/">newmacaulayschool2012@gmail.com</a>
                  <a className="hover:text-red-500" href="/" target="blank" rel="noopener noreferrer">+91-9148788100</a>
                </nav>
              </div>

              <div className="custom-font-stack text-center sm:text-center">
                <a className="font-medium text-white hover:text-red-500" href='https://unit3.newmacaulayenglishschool.com' target='blank' rel="noopener noreferrer">Unit 3</a>
                <nav className="flex flex-col mt-4 space-y-2 text-sm text-white">
                  <a className="hover:text-red-500 footer-email" href="mailto:macaulayschoolbaguru@gmail.com">macaulayschoolbaguru@gmail.com</a>
                  <a className="hover:text-red-500" href="/">+91-7624856170</a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <p class=" text-large text-black custom-font-stack" style={{  padding: '10px', backgroundColor: '#e8e8e5' }}>
          © 2022 Macaulay Group of Schools | Designed & Developed by Drighna Technology pvt Ltd.
        </p>
      </footer>
    </>
  );
};

export default F;
