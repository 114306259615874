import React from 'react'
import Nav from '../Component/nav/Index'
import Hero from '../Component/home/Hero'
import Introduction from '../Component/home/Introduction'
import Home from '../Component/home/Index'
import Management from '../Component/management/Index'
import Highlights from '../Component/highlights/Index'
import Pri from '../Component/management/Pri'
import F from '../Component/footer/F'

const HomeTrial = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Introduction />
      <Home />
      <Management />
      <Pri />
      <Highlights />
      <F/>
    </>
  )
}

export default HomeTrial
