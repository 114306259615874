import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeTrial from './pages/HomeTrial';

function App() {
  
  return (
    <div className="App">
     <BrowserRouter>
     <Routes>
      <Route path='/' exact element={<HomeTrial/>}/>
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
